export const StaticTextFields = {
    header: {
        pageName: 'Header',
        list: [
            {
                label: "Header - services",
                key: "header_item_services"
            },
            {
                label: "Header - about",
                key: "header_item_about"
            },
            {
                label: "Header - news",
                key: "header_item_news"
            },
            {
                label: "Header - contact",
                key: "header_item_contact"
            },
            {
                label: "Header - products",
                key: "header_item_products"
            },

        ]
    },
    homepage: {
        pageName: 'Homepage',
        list: [
            {
                label: "Homepage - more service",
                key: "homepage_more_service"
            },
            {
                label: "Homepage - info btn",
                key: "homepage_more_info_btn"
            },
            {
                label: "Homepage - news title",
                key: "homepage_news_title"
            },
            {
                label: "Homepage - news description",
                key: "homepage_news_description"
            },
            {
                label: "Homepage - contact title",
                key: "homepage_contact_title"
            },
            {
                label: "Homepage - contact description",
                key: "homepage_contact_description"
            },
            {
                label: "Homepage - contact placeholder email",
                key: "homepage_contact_placeholder_email"
            },
            {
                label: "Homepage - contact button send",
                key: "homepage_contact_button_send"
            },
            {
                label: "Homepage - homePackage title",
                key: "homepage_homePackage_title"
            },
            {
                label: "Homepage - homePackage description",
                key: "homepage_homePackage_description"
            },
        ]
    },
    services:{
        pageName:"Services",
        list:[
            {
                label:"Service - more",
                key:"service_more"
            },
            {
                label:"Service - modal feedback title",
                key:"service_modal_feedback_title"
            },
            {
                label:"Service - modal feedback description",
                key:"service_modal_feedback_description"
            },
        ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - section sites title",
                key: "footer_section_sites_title"
            },
            {
                label: "Footer - section home",
                key: "footer_section_home"
            },
            {
                label: "Footer - section services",
                key: "footer_section_services"
            },
            {
                label: "Footer - section licenses",
                key: "footer_section_licenses"
            },
            {
                label: "Footer - section about",
                key: "footer_section_about"
            },
            {
                label: "Footer - section news",
                key: "footer_section_news"
            },
            {
                label: "Footer - section staff",
                key: "footer_section_staff"
            },
            {
                label: "Footer - section contact",
                key: "footer_section_contact"
            },
            {
                label: "Footer - products",
                key: "footer_section_products"
            },
            {
                label: "Footer - section subscription",
                key: "footer_section_subscription"
            },
            {
                label: "Footer - section number",
                key: "footer_section_number"
            },
            {
                label: "Footer - section feedback",
                key: "footer_section_feedback"
            },
            {
                label: "Footer - copyright",
                key: "footer_copyright"
            },
            {
                label: "Footer - section developed",
                key: "footer_section_developed_by_text"
            },
            {
                label: "Footer - presentation",
                key: "footer_section_button_presentation"
            },
        ]
    },
    blog: {
        pageName: 'Blog',
        list: [
            {
                label: "Blog - Page prev",
                key: "blog_page_prev"
            },
            {
                label: "Blog - Page next",
                key: "blog_page_next"
            },
            {
                label: "Blog - Page subscribe subtitle",
                key: "blog_page_subscribe_subtitle"
            },
            {
                label: "Blog - Page subscribe title",
                key: "blog_page_subscribe_title"
            },
            {
                label: "Blog - Page subscribe description",
                key: "blog_page_subscribe_description"
            },
            {
                label: "Blog - Page last news",
                key: "blog_page_last_news_title"
            },
            {
                label: "Blog - Page all news",
                key: "blog_page_all_news_title"
            },
            {
                label: "Blog - Page data title",
                key: "blog_pageDetail_data_title"
            },
        ]
    },
    about: {
        pageName: 'About',
        list: [
            {
                label: "About - partners title",
                key: "about_us_partners_title"
            },
            {
                label: "About - partners description",
                key: "about_us_partners_description"
            },
            {
                label: "About - feedback title",
                key: "about_us_feedback_title"
            },
            {
                label: "About - feedback description",
                key: "about_us_feedback_description"
            },
            {
                label: "About - feedback name placeholder",
                key: "about_us_feedback_name_placeholder"
            },
            {
                label: "About - feedback name label",
                key: "about_us_feedback_name_label"
            },
            {
                label: "About - feedback phoneNumber placeholder",
                key: "about_us_feedback_phoneNumber_placeholder"
            },
            {
                label: "About - feedback phoneNumber label",
                key: "about_us_feedback_phoneNumber_label"
            },
            {
                label: "About - feedback btn",
                key: "about_us_feedback_btn"
            },
        ]
    },
    product: {
        pageName: 'Product',
        list: [
            {
                label: "Product - page title",
                key: "product_page_title"
            },
            {
                label: "Product - page description",
                key: "product_page_description"
            },
            {
                label: "Product - page order title",
                key: "product_page_order_title"
            },
            {
                label: "Product - page order description",
                key: "product_page_order_description"
            },
        ]
    },
    contacts: {
        pageName: 'Contacts',
        list: [
            {
                label: "Contacts - Info title",
                key: "contact_info_title"
            },
            {
                label: "Contacts - Info description",
                key: "contact_info_description"
            },
            {
                label: "Contacts - email title",
                key: "contact_email_title"
            },
            {
                label: "Contacts - number title",
                key: "contact_number_title"
            },
            {
                label: "Contacts - first number",
                key: "contact_first_number"
            },
            {
                label: "Contacts - second number",
                key: "contact_second_number"
            },
            {
                label: "Contacts - third number",
                key: "contact_third_number"
            },
            {
                label: "Contacts - address title",
                key: "contact_address_title"
            },
            {
                label: "Contacts - title",
                key: "contact_title"
            },
            {
                label: "Contacts - description",
                key: "contact_description"
            },
            {
                label: "Contacts - Page placeholder name",
                key: "contacts_page_placeholder_name"
            },
            {
                label: "Contacts - Page label name",
                key: "contacts_page_label_name"
            },
            {
                label: "Contacts - Page placeholder lastName",
                key: "contacts_page_placeholder_lastName"
            },
            {
                label: "Contacts - Page label lastName",
                key: "contacts_page_label_lastName"
            },
            {
                label: "Contacts - Page placeholder email",
                key: "contacts_page_placeholder_email"
            },
            {
                label: "Contacts - Page label email",
                key: "contacts_page_label_email"
            },
            {
                label: "Contacts - Page placeholder phoneNumber",
                key: "contacts_page_placeholder_phoneNumber"
            },
            {
                label: "Contacts - Page label phoneNumber",
                key: "contacts_page_label_phoneNumber"
            },
            {
                label: "Contacts - Page placeholder message",
                key: "contacts_page_placeholder_message"
            },
            {
                label: "Contacts - Page label message",
                key: "contacts_page_label_message"
            },
            {
                label: "Contacts - Page btn send",
                key: "contacts_page_btn_send"
            },
            {
                label: "Subscribe - btn",
                key: "subscribe_btn"
            },
            {
                label: "Subscribe - title",
                key: "subscribe_title"
            },
            {
                label: "Subscribe - description",
                key: "subscribe_description"
            },
        ]
    },
}